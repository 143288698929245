var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "community-container" },
    [
      _c("div", { staticClass: "community-title" }, [_vm._v("系统助手")]),
      _c("div", { staticClass: "community-info" }, [
        _vm._v(
          " 官方默认的社区助手与官方客服号打通，官方客服的头像、名称、关联账号在此处配置 "
        ),
      ]),
      _c("TablePaging", {
        attrs: {
          "column-list": _vm.columnList1,
          "is-pagination": false,
          loading: _vm.loading1,
          "max-height": "100%",
          "page-info": _vm.pageInfo,
          "show-header": false,
          "table-data": _vm.assistantCusList,
        },
        on: {
          handleCurrentChange: _vm.handleCurrentChange,
          handleManage: _vm.handleManage,
          handleUpdateStatus: _vm.handleUpdateStatus,
        },
        scopedSlots: _vm._u([
          {
            key: "name",
            fn: function (ref) {
              var row = ref.row
              return [
                _c(
                  "div",
                  { staticClass: "community-item" },
                  [
                    _c("el-image", {
                      staticClass: "activity-list-cover",
                      attrs: {
                        "preview-src-list": [row.pic_tburl],
                        src: row.pic_tburl,
                      },
                    }),
                    _c("span", [_vm._v(_vm._s(row.name))]),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "type",
            fn: function (ref) {
              var row = ref.row
              return [_vm._v(_vm._s(_vm.typeObj[row.type]))]
            },
          },
          {
            key: "send_num",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("span", [_vm._v("群发总次数：" + _vm._s(row.send_num))]),
              ]
            },
          },
        ]),
      }),
      _c("div", { staticClass: "community-title m-t-20" }, [
        _vm._v("自定义助手"),
      ]),
      _c("div", { staticClass: "community-info" }, [
        _vm._v(" 支持新增多个助手账号，每个账号单独管理，单独发送消息给用户 "),
      ]),
      _c("TablePaging", {
        attrs: {
          "button-list": _vm.buttonList,
          "column-list": _vm.columnList2,
          "is-pagination": false,
          "is-table": _vm.assistantList.length != 0,
          loading: _vm.loading2,
          "max-height": "100%",
          "page-info": _vm.pageInfo,
          "show-header": false,
          "table-data": _vm.assistantList,
        },
        on: {
          handleCreate: _vm.handleCreate,
          handleCurrentChange: _vm.handleCurrentChange,
          handleManage: _vm.handleManage,
          handleRemove: _vm.handleRemove,
          handleUpdateStatus: _vm.handleUpdateStatus,
        },
        scopedSlots: _vm._u([
          {
            key: "name",
            fn: function (ref) {
              var row = ref.row
              return [
                _c(
                  "div",
                  { staticClass: "community-item" },
                  [
                    _c("el-image", {
                      staticClass: "activity-list-cover",
                      attrs: {
                        "preview-src-list": [row.pic_tburl],
                        src: row.pic_tburl,
                      },
                    }),
                    _c("span", [_vm._v(_vm._s(row.name))]),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "send_num",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("span", [_vm._v("群发总次数：" + _vm._s(row.send_num))]),
              ]
            },
          },
        ]),
      }),
      _c("CreateDialog", {
        ref: "createDialog",
        on: { getAssistantList: _vm.getAssistantList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }