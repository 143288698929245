<template>
  <div class="community-container">
    <div class="community-title">系统助手</div>
    <div class="community-info">
      官方默认的社区助手与官方客服号打通，官方客服的头像、名称、关联账号在此处配置
    </div>
    <!-- 表格 -->
    <TablePaging
      :column-list="columnList1"
      :is-pagination="false"
      :loading="loading1"
      max-height="100%"
      :page-info="pageInfo"
      :show-header="false"
      :table-data="assistantCusList"
      @handleCurrentChange="handleCurrentChange"
      @handleManage="handleManage"
      @handleUpdateStatus="handleUpdateStatus"
    >
      <!-- 名称 -->
      <template #name="{ row }">
        <div class="community-item">
          <el-image
            class="activity-list-cover"
            :preview-src-list="[row.pic_tburl]"
            :src="row.pic_tburl"
          />
          <span>{{ row.name }}</span>
        </div>
      </template>
      <!-- 类型 -->
      <template #type="{ row }">{{ typeObj[row.type] }}</template>
      <!-- 群发次数 -->
      <template #send_num="{ row }">
        <span>群发总次数：{{ row.send_num }}</span>
      </template>
    </TablePaging>
    <div class="community-title m-t-20">自定义助手</div>
    <div class="community-info">
      支持新增多个助手账号，每个账号单独管理，单独发送消息给用户
    </div>
    <!-- 表格 -->
    <TablePaging
      :button-list="buttonList"
      :column-list="columnList2"
      :is-pagination="false"
      :is-table="assistantList.length != 0"
      :loading="loading2"
      max-height="100%"
      :page-info="pageInfo"
      :show-header="false"
      :table-data="assistantList"
      @handleCreate="handleCreate"
      @handleCurrentChange="handleCurrentChange"
      @handleManage="handleManage"
      @handleRemove="handleRemove"
      @handleUpdateStatus="handleUpdateStatus"
    >
      <!-- 名称 -->
      <template #name="{ row }">
        <div class="community-item">
          <el-image
            class="activity-list-cover"
            :preview-src-list="[row.pic_tburl]"
            :src="row.pic_tburl"
          />
          <span>{{ row.name }}</span>
        </div>
      </template>
      <!-- 群发次数 -->
      <template #send_num="{ row }">
        <span>群发总次数：{{ row.send_num }}</span>
      </template>
    </TablePaging>
    <CreateDialog ref="createDialog" @getAssistantList="getAssistantList" />
  </div>
</template>

<script>
  import TablePaging from '@/components/Table'
  import tableMixins from '@/mixins/tableCommon.vue'
  import CreateDialog from './components/createDialog'
  import {
    getAssistantList,
    assistantStatusUpdate,
    assistantDel,
  } from '@/api/operate'
  export default {
    components: {
      TablePaging,
      CreateDialog,
    },
    mixins: [tableMixins],
    data() {
      return {
        loading1: false,
        loading2: false,
        assistantCusList: [],
        assistantList: [],
        typeObj: {
          1: '官方',
          3: '交友',
          4: '新悦购',
          5: '钱包/积分',
          6: '群通知',
          7: '礼物通知',
          8: '激励中心',
        },
        buttonList: Object.freeze([
          {
            label: '新增助手',
            type: 'primary',
            icon: 'el-icon-plus',
            eventName: 'handleCreate',
          },
        ]),
        columnList1: [
          { slotName: 'name', width: 250 },
          { slotName: 'type' },
          { prop: 'secret', width: 350 },
          { slotName: 'send_num' },
          {
            isButton: true,
            btnList: [
              {
                label: '管理',
                type: 'primary',
                eventName: 'handleManage',
              },
              {
                label: '开启',
                type: 'primary',
                eventName: 'handleUpdateStatus',
                showName: 'status',
                showStatusList: [-1],
              },
              {
                label: '关闭',
                type: 'danger',
                eventName: 'handleUpdateStatus',
                showName: 'status',
                showStatusList: [1],
              },
            ],
          },
        ],
        columnList2: [
          { slotName: 'name' },
          { slotName: 'send_num' },
          {
            isButton: true,
            btnList: [
              {
                label: '管理',
                type: 'primary',
                eventName: 'handleManage',
              },
              {
                label: '删除',
                type: 'danger',
                eventName: 'handleRemove',
              },
              {
                label: '开启',
                type: 'primary',
                eventName: 'handleUpdateStatus',
                showName: 'status',
                showStatusList: [-1],
              },
              {
                label: '关闭',
                type: 'danger',
                eventName: 'handleUpdateStatus',
                showName: 'status',
                showStatusList: [1],
              },
            ],
          },
        ],
      }
    },
    created() {
      this.getAssistantList()
    },
    methods: {
      async getAssistantList() {
        const res = await getAssistantList()
        if (res.success) {
          this.assistantCusList = res.data.assistant_cus || []
          this.assistantList = res.data.assistant_list || []
        }
      },
      // 新增-对话框
      handleCreate() {
        this.$refs.createDialog.handleOpen('新增助手')
      },
      // 关闭/开启
      async handleUpdateStatus(val, index, buttonName) {
        const params = {
          id: val.id,
          status: buttonName === '关闭' ? -1 : 1,
        }
        const res = await assistantStatusUpdate(params)
        this.$message({
          type: res.success ? 'success' : 'error',
          message: res.success ? `${buttonName}成功` : `${buttonName}失败`,
        })
        if (res.success) this.getAssistantList()
      },
      // 删除
      handleRemove(val, index, buttonName) {
        this.$confirm(`此操作将${buttonName}当前内容, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            const params = {
              id: val.id,
            }
            const res = await assistantDel(params)
            this.$message({
              type: res.success ? 'success' : 'error',
              message: res.success ? `${buttonName}成功` : `${buttonName}失败`,
            })
            if (res.success) this.getAssistantList()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            })
          })
      },
      // 管理
      handleManage(val) {
        console.log(val)
        this.$router.push({
          path: 'assistantManage',
          query: {
            id: val.id,
          },
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.community';
  #{$base}-container {
    #{$base}-title {
      font-size: 15px;
      font-weight: bold;
      margin: 5px 0;
    }
    #{$base}-info {
      font-size: 13px;
      color: $base-color-text-secondary;
      margin-bottom: 5px;
    }
    #{$base}-item {
      display: flex;
      align-items: center;
      span {
        margin-left: 15px;
      }
    }
    .m-t-20 {
      margin-top: 20px;
    }
  }
</style>
