<template>
  <el-dialog
    append-to-body
    :before-close="handleClose"
    :title="title"
    :visible.sync="dialogVisible"
    width="50%"
  >
    <Form
      ref="create"
      :column="column"
      :query="query"
      ref-name="createDialog"
      :rules="rules"
      @handleImageUrl="handleImageUrl"
    />
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button :loading="loading" type="primary" @click="onSubmit">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { assistantSave } from '@/api/operate'
  import Form from '@/components/Form'
  export default {
    components: {
      Form,
    },
    data() {
      return {
        title: null,
        dialogVisible: false,
        loading: false,
        query: {
          pic: null,
          name: null,
          type: null,
        },
        column: [
          {
            label: '上传图片',
            prop: 'pic',
            type: 'uploadImage',
            width: 80,
            height: 80,
            eventName: 'handleImageUrl',
            tips: '图片建议尺寸：120x120px',
          },
          {
            label: '名称',
            prop: 'name',
          },
        ],
        rules: {
          pic: [{ required: true, trigger: 'blur', message: '请上传图片' }],
          name: [{ required: true, trigger: 'blur', message: '请输入名称' }],
        },
      }
    },
    methods: {
      handleOpen(title) {
        this.title = title
        this.query.type = 2
        this.dialogVisible = true
      },
      handleClose(done) {
        done()
        this.cancel()
      },
      // 提交
      async onSubmit() {
        this.$refs.create.$refs.createDialog.validate(async (valid) => {
          if (valid) {
            if (this.loading) return
            this.loading = true
            const params = Object.assign({}, this.query)
            const res = await assistantSave(params)
            this.loading = false
            if (res.success) {
              this.cancel()
              this.$emit('getAssistantList')
              this.$message.success('新增成功')
            }
          }
        })
      },
      // 取消
      cancel() {
        this.dialogVisible = false
        this.$refs.create.resetFields()
        setTimeout(() => {
          for (let key in this.query) {
            this.query[key] = null
          }
        }, 500)
      },
      // 获取图片上传地址
      handleImageUrl(prop, id) {
        this.query[prop] = id
      },
    },
  }
</script>

<style lang="scss" scoped></style>
